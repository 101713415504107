export default [
  "1-B1",
  "10MM",
  "10X9",
  "10mm",
  "10mmMM",
  "10x9",
  "10x9mm",
  "11MM",
  "11P",
  "11Q",
  "11Q13",
  "11mm",
  "11p",
  "11q",
  "11q13",
  "12P",
  "12P13",
  "12p",
  "13MM",
  "13mm",
  "15X15",
  "15x15",
  "18MM",
  "18mm",
  "1A-1C",
  "1A-1T",
  "1A-2A",
  "1A1",
  "1A2",
  "1A3",
  "1A4",
  "1CMX8MM",
  "1ST",
  "1cmx8mm",
  "1st",
  "22q",
  "2A-2D",
  "2MM",
  "2MM2",
  "2ND",
  "2X2",
  "2X2MM",
  "2X3MM",
  "2mm",
  "2mm2",
  "2nd",
  "2x2",
  "2x2mm",
  "2x3mm",
  "3-4mm",
  "34BETAE12",
  "34b",
  "34betaE12",
  "3MM",
  "3MM2",
  "3MM22",
  "3RD",
  "3X2MM",
  "3X3",
  "3X3MM",
  "3X4MM",
  "3mm",
  "3mm2",
  "3mm2-1cm2",
  "3rd",
  "3x2mm",
  "3x3",
  "3x4mm",
  "4MM",
  "4MM2",
  "4MMmm",
  "4TH",
  "4X3",
  "4X3MM",
  "4X4MM",
  "4X5MM",
  "4X5X1",
  "4mm",
  "4mm2",
  "4mmMM",
  "4th",
  "4x3",
  "4x3mm",
  "4x4mm",
  "4x4x1",
  "4x5",
  "4x5mm",
  "4x5x1",
  "5-fluorouracil",
  "5FU",
  "5MM",
  "5MM2",
  "5TH",
  "5X4",
  "5X4MM",
  "5X5",
  "5X5MM",
  "5X6MM",
  "5mm",
  "5mm2",
  "5th",
  "5x4",
  "5x4mm",
  "5x5",
  "5x5mm",
  "5x6mm",
  "6CM",
  "6MM",
  "6MM2",
  "6P25",
  "6X4",
  "6X5",
  "6cm",
  "6mm",
  "6mm2",
  "6p25",
  "6x4",
  "6x5",
  "7MM",
  "7X4MM",
  "7X5",
  "7X5MM",
  "7X6",
  "7X6MM",
  "7X7",
  "7mm",
  "7x4mm",
  "7x5",
  "7x5mm",
  "7x6",
  "7x6mm",
  "7x7",
  "8MM",
  "8MM2",
  "8MMmm",
  "8Q24",
  "8X5",
  "8X5MM",
  "8X6CM",
  "8X6MM",
  "8X9MM",
  "8mm",
  "8mm2",
  "8q24",
  "8x5",
  "8x5mm",
  "8x6cm",
  "8x6mm",
  "8x9mm",
  "9MM",
  "9MM2",
  "9X6MM",
  "9X8MM",
  "9X9MM",
  "9mm",
  "9mm2",
  "9x6mm",
  "9x8mm",
  "9x9mm",
  "A1",
  "A1-1",
  "A1-3",
  "A1-4",
  "A1-5",
  "A1-6",
  "A1-A2",
  "A1-A3",
  "A1-A4",
  "A1-A5",
  "A1-A8",
  "A1-B1",
  "A15",
  "A19mm",
  "A2",
  "A2-7",
  "A2-A4",
  "A22",
  "A22-3105",
  "A3-1",
  "A3-A4",
  "A3-A5",
  "A4",
  "A5",
  "A5-A6",
  "A6",
  "A7-A8",
  "A9-A14",
  "ABD",
  "ABRISHAMI",
  "ACAD",
  "ACANTHOLYSIS",
  "ACANTHOLYTIC",
  "ACANTHOMA",
  "ACANTHOSIS",
  "ACANTHOTIC",
  "ACELLULARITY",
  "ACHILLES",
  "ACNEIFORM",
  "ACRAL",
  "ACROANGIODERMATITIS",
  "ACROSYRINGEAL",
  "ACUMINATA",
  "ACUMINATUMNS",
  "AD22",
  "ADAMANTINOID",
  "ADENOLIPOMA",
  "ADENOMYOEPITHELIOMA",
  "ADIPOPHILIN",
  "ADNEXAE",
  "ADNEXAL",
  "ADNEXOCENTRIC",
  "ADNEXOCENTRICITY",
  "ADNEXOTROPISM",
  "ADVENTITIAL",
  "AE1",
  "AE3",
  "AFH",
  "AITCL",
  "AJCC",
  "ALCIAN",
  "ALDY",
  "ALOPETIC",
  "ALVAL",
  "AMELANOTIC",
  "AMM",
  "AMN",
  "AMONGST",
  "AMPHOPHILIC",
  "ANDROGENETIC",
  "ANETODORMA",
  "ANGIOCENTRICITY",
  "ANGIOFIBROMA",
  "ANGIOFIBROMATOUS",
  "ANGIOFIBROTIC",
  "ANGIOHISTIOCYTOMA",
  "ANGIOLIPOMA",
  "ANGIOLIPOMATOUS",
  "ANGIOLYMPHOID",
  "ANGIOMATOID",
  "ANGIOMATOSIS",
  "ANGIOMYXOMA",
  "ANGIOPLASIA",
  "ANGIOSARCOMA",
  "ANGOCENTRICITYLEAR",
  "ANNULARE",
  "ANTONI",
  "ANUCLEATE",
  "APHTHOUS",
  "ARBORIZING",
  "AREATA",
  "ARNAUD",
  "ARTHROPLASTY",
  "ARZENO",
  "ATN",
  "ATOPIC",
  "ATOPY",
  "ATROPHICANS",
  "ATROPHODERMA",
  "ATYPIA",
  "ATYPICALITY",
  "ATYPICALITYA",
  "ATYPICALS",
  "ATYPISM",
  "AUSPITZ",
  "AUTOECZEMATIZATION",
  "AUTOINFLAMMATORY",
  "AUTOSOMAL",
  "AXONAL",
  "Abdaljaleel",
  "Abrishami",
  "Acad",
  "Acral",
  "Adnexae",
  "Alcian",
  "Antoni",
  "Arnaud",
  "Arzeno",
  "Auspitz",
  "B1",
  "B1-B2",
  "B1-B3",
  "B1-B4",
  "B1-B5",
  "BANDLIKE",
  "BAP1",
  "BAP1-INACTIVATED",
  "BAP1-inactivated",
  "BAPOMA",
  "BAPoma",
  "BARKSDALE",
  "BASALOID",
  "BASTIAN",
  "BCL1",
  "BCL2",
  "BCL6",
  "BEDNAR",
  "BEHCET",
  "BER",
  "BFH",
  "BHAWAN",
  "BICLONALITY",
  "BIPHASIC",
  "BLANCHABLE",
  "BLASCHKITIS",
  "BLASCHKOID",
  "BLASTIC",
  "BLATCHFORD",
  "BLENORRHAGICUM",
  "BMN",
  "BOWENOID",
  "BPDCN",
  "BRAF",
  "BRESLOW",
  "BRONCHIOLOALVEOLAR",
  "BRONCHOGENIC",
  "BROUHA",
  "BULLAE",
  "BULLOUS",
  "Barksdale",
  "Bastian",
  "Bednar",
  "Behcet",
  "Ber",
  "Ber-EP4",
  "Bhawan",
  "Birt-Hogg-Dube",
  "Blatchford",
  "Blue-grey",
  "Breslow",
  "Brouha",
  "Brown-Brenn",
  "Brunsting-Perry",
  "Bullae",
  "C1",
  "C1-C2",
  "C1-C5",
  "C2-C4",
  "C22",
  "C22-1498",
  "C22-2414",
  "CALCINOSIS",
  "CALONJE",
  "CALPONIN",
  "CAMISA",
  "CAMTA1",
  "CANDIDIASIS",
  "CAPECITABINE",
  "CAPITIS",
  "CARCINOID",
  "CATAGEN",
  "CATALOGUED",
  "CATENIN",
  "CAVUM",
  "CBCL",
  "CCCA",
  "CCND1",
  "CD10",
  "CD117",
  "CD123",
  "CD138",
  "CD15",
  "CD1A3",
  "CD1a",
  "CD20",
  "CD21",
  "CD23",
  "CD3",
  "CD3-PREDOMINANT",
  "CD3-predominant",
  "CD30",
  "CD31",
  "CD34",
  "CD4",
  "CD43",
  "CD45",
  "CD5",
  "CD56",
  "CD61",
  "CD61A",
  "CD61a",
  "CD63",
  "CD68",
  "CD79A",
  "CD79a",
  "CD8",
  "CDKN2A",
  "CDX2",
  "CEA",
  "CEAN",
  "CENTRIFUGUM",
  "CEREBRIFORM",
  "CHEILITIS",
  "CHOLINERGIC",
  "CHONDRODERMATITIS",
  "CHONDROID",
  "CHONDROOSSIFICATION",
  "CHORNY",
  "CHROMOGRANIN",
  "CHROMOMYCOSIS",
  "CHRONDRODERMATITIS",
  "CHRONDROOSSIFICATION",
  "CHRONICA",
  "CHRONICUS",
  "CJS",
  "CK20",
  "CK34BE12",
  "CK5",
  "CK7",
  "CK8",
  "CLASIS",
  "CLAVICULAR",
  "CLIA",
  "CLINICOPATHOLOGIC",
  "CLL",
  "CLONALITY",
  "CLONALLY",
  "CMN",
  "CNH",
  "COCKERELL",
  "COEXPRESS",
  "COEXPRESSES",
  "COEXPRESSING",
  "COEXPRESSION",
  "COLLAGENOUS",
  "COLLARETTE",
  "COLUMNELLA",
  "COMEDONAL",
  "COMEDONE",
  "COMEDONES",
  "CONFLUENTLY",
  "CONTAGIOSUM",
  "CORDLIKE",
  "CORNEOCYTES",
  "CORNEOCYTOPHAGOCYSIS",
  "CORNEOCYTOPHAGOCYTIC",
  "CORNEOCYTOPHAGOCYTOSIS",
  "CORNEOCYTOPHAGOCYTic",
  "CORNEUM",
  "CORNIFICATION",
  "CORNIFIED",
  "CORNIFY",
  "CORNIFYING",
  "CORNOID",
  "CORPORIS",
  "COVERSLIP",
  "COWDEN",
  "CRATERIFORM",
  "CRURIS",
  "CTCL",
  "CTD",
  "CUBOIDAL",
  "CUTANEA",
  "CUTICULATED",
  "CUTICULATION",
  "CYLINDROCARCINOMA",
  "CYLINDROMA",
  "CYLINDROSPIRADENOCARCINOMA",
  "CYMBA",
  "CYSTADENOMA",
  "CYTOGENETIC",
  "CYTOHISTOMORPHOLOGIC",
  "CYTOKERATIN",
  "CYTOKERATINS",
  "CYTOLOGICALLY",
  "CYTOMEGALIC",
  "CYTOMEGALY",
  "CYTOMORPHOLOGIC",
  "CYTOMORPHOLOGICALLY",
  "CYTOMORPHOLOGIES",
  "CYTOMORPHOLOGY",
  "CYTOPATHIC",
  "CYTOPATHOLOGIC",
  "CYTOTOXICITY",
  "Calonje",
  "Camisa",
  "Chorny",
  "Cockerell",
  "Cockerell's",
  "Cowden",
  "Cutan",
  "D1-D2",
  "D2",
  "D2-40",
  "DARIER",
  "DDA",
  "DDX",
  "DE",
  "DEBULK",
  "DEBULKED",
  "DEBULKING",
  "DEGRANULATED",
  "DEGRANULATION",
  "DEMODECIDOSIS",
  "DENDROCYTES",
  "DEPIGMENTED",
  "DERM",
  "DERMATITIS",
  "DERMATITIs",
  "DERMATOFIBROMA",
  "DERMATOFIBROMAS",
  "DERMATOFIBROSARCOMA",
  "DERMATOHELIOSIS",
  "DERMATOHELIOTIC",
  "DERMATOL",
  "DERMATOMYOFIBROMA",
  "DERMATOMYOFIBROMAS",
  "DERMATOMYOSITIS",
  "DERMATOPATHOLOGIC",
  "DERMATOPATHOLOGIST",
  "DERMATOPATHOLOGISTS",
  "DERMATOPATHOLOGY",
  "DERMATOPHYTIC",
  "DERMATOPHYTOSIS",
  "DERMAWAN",
  "DERMOEPIDERMAL",
  "DERMOPATHY",
  "DERMOSCOPIC",
  "DERMOSCOPY",
  "DERMTECH",
  "DESMOPLASIA",
  "DESMOPLASTIC",
  "DESTOMBES-ROSAI-DORFMAN",
  "DFSP",
  "DIABETICORUM",
  "DIF",
  "DIGITATED",
  "DISSEMINATUS",
  "DIUTINUM",
  "DL20-86794",
  "DLE",
  "DOXYCYCLINE",
  "DP23-469",
  "DPNS",
  "DRINKARD",
  "DRS",
  "DSAP",
  "DSAPKARD",
  "DUCTAL",
  "DUCTULAR",
  "DUNNIHOO",
  "DYSCHROMIC",
  "DYSCHROMICUM",
  "DYSHIDROSIFORM",
  "DYSHIDROSIS",
  "DYSHIDROTIC",
  "DYSKERATOMA",
  "DYSKERATOSIS",
  "DYSKERATOTIC",
  "DYSMATURATION",
  "DYSP",
  "DYSPIGMENTATION",
  "DYSPIGMENTED",
  "Darier",
  "Dermatol",
  "Dermatopathol",
  "Dermatopathology",
  "Dermawan",
  "Dermtech",
  "Destombes-Rosai-Dorfman",
  "Drinkard",
  "Drs",
  "Dunnihoo",
  "Dyshidrotic",
  "Dysp",
  "Dyspigmented",
  "EAC",
  "EBER",
  "EBV",
  "ECM",
  "ECTASIA",
  "ECTATIC",
  "ECTOPY",
  "EDF",
  "EED",
  "EFUDEX",
  "ELASTICUM",
  "ELASTOLYSIS",
  "ELASTOPHAGOCYTOSIS",
  "ELASTOSIS",
  "ELASTOTIC",
  "ELEVATUM",
  "EMA",
  "EMPD",
  "EMPERIPOLESIS",
  "ENDOPHYTIC",
  "EOSINOPHILIA",
  "EP4",
  "EPIDERMODYSPLASIA",
  "EPIDERMOLYTIC",
  "EPIDERMOTROPIC",
  "EPIDERMOTROPISM",
  "EPIGASTRIC",
  "EPITHELIOID",
  "EPITHELIOTROPISM",
  "ERYTHEMATOSUS",
  "ERYTHEMATOSUS-LIKE",
  "ERYTHEMATOUS",
  "ERYTHRASMA",
  "ERYTHRODERMA",
  "ERYTHRODERMIC",
  "ERYTHRODYSESTHESIA",
  "ETIOPATHOGENESIS",
  "EVAL",
  "EVALUABLE",
  "EVG",
  "EWSR1",
  "EWSR1-ATF1",
  "EWSR1-CREB1",
  "EXANTHEM",
  "EXCISIONAL",
  "EXOCYTOSIS",
  "EXOCYTOTIC",
  "EXOENDOPHYTIC",
  "EXOPHYTIC",
  "EXTRACUTANEOUS",
  "EXTRAFACIAL",
  "EXTRAGENITAL",
  "EXTRAMAMMARY",
  "EXTRANODAL",
  "Efudex",
  "Epitheliotropism",
  "Eval",
  "Extragenital",
  "FACIALE",
  "FACIEI",
  "FASCIAL",
  "FFA",
  "FIBRILLAR",
  "FIBROADENOMA",
  "FIBROBLASTOMA",
  "FIBROCOLLAGENOUS",
  "FIBROCYSTIC",
  "FIBROCYTES",
  "FIBROEPITHELIAL",
  "FIBROEPITHELIOMATOUS",
  "FIBROFATTY",
  "FIBROFOLLICULOMA",
  "FIBROHISTIOCYTIC",
  "FIBROLIPOMA",
  "FIBROMAS",
  "FIBROMYXOID",
  "FIBROOSSEOUS",
  "FIBROPLASIA",
  "FIBROSARCOMATOUS",
  "FIBROSING",
  "FIBROXANTHOMA",
  "FISSURATUM",
  "FITE",
  "FLUCTUANCE",
  "FOLIACEUS",
  "FOLLICULITIS",
  "FOLLICULOCENTRIC",
  "FOLLICULOCENTRICITY",
  "FOLLICULOSEBACEOUS",
  "FOLLICULOTROPIC",
  "FOLLICULOTROPISM",
  "FOUCHARDIERE",
  "FPHL",
  "FRIEDEN",
  "FRONTOPARIETAL",
  "FUNGOIDES",
  "FUSIFOR",
  "Fite",
  "Fontana-Masson",
  "Fouchardiere",
  "Frieden",
  "Frontoparietal",
  "G",
  "GAMMOPATHY",
  "GANGRENOSUM",
  "GARBE",
  "GATA3",
  "GEN22",
  "GENERIS",
  "GENODERMATOSIS",
  "GENOTYPING",
  "GERMLINE",
  "GIEMSA",
  "GLIOBLASTOMA",
  "GLOMANGIOSARCOMA",
  "GLOMERULOID",
  "GLOMUS",
  "GNA",
  "GOTTRON",
  "GR",
  "GRANULOMATOSIS",
  "GRANULOMATOUS",
  "GRANULOSUM",
  "Gar",
  "Giemsa",
  "Gottron",
  "Gru",
  "HAK",
  "HALOGENODERMA",
  "HAMARTOMA",
  "HD20-2331",
  "HEADINGTON",
  "HELICIS",
  "HEMANGIOENDOTHELIOMA",
  "HEMATOLYMPHOID",
  "HEMATOLYMPHOPROLIFERATIVE",
  "HEMOSIDERIN",
  "HEMOSIDEROPHAGES",
  "HEMOSIDEROSIS",
  "HEMOSIDEROTIC",
  "HEPATOBILIARY",
  "HER2",
  "HERPESVIRAL",
  "HERPesviral",
  "HHV8",
  "HIBBARD",
  "HIDRADENITIS",
  "HIDRADENOCARCINOMA",
  "HIDRADENOMA",
  "HIDRADENOMAS",
  "HIDROACANTHOMA",
  "HIDROCYSTOMA",
  "HIDRocystoma",
  "HISTIOCYTIC",
  "HISTIOCYTOID",
  "HISTIOCYTOSIS",
  "HISTIOCYTOSISICALLY",
  "HISTOMORPHOLOGIC",
  "HISTOMORPHOLOGICALLY",
  "HISTOMORPHOLOGY",
  "HISTOPATHOLOGICALLY",
  "HMB",
  "HMB45",
  "HMK",
  "HPV",
  "HRAS",
  "HRAS-mutated",
  "HSIL",
  "HSV",
  "HYALINIZED",
  "HYALINIZING",
  "HYALURONIC",
  "HYERP",
  "HYPERCELLULAR",
  "HYPERCELLULARITY",
  "HYPERCHROMASIA",
  "HYPERCHROMATIC",
  "HYPERCOAGULABILITY",
  "HYPERCOAGULABLE",
  "HYPERCOAGULATION",
  "HYPERCONVOLUTED",
  "HYPEREOSINOPHIL",
  "HYPEREOSINOPHILIA",
  "HYPEREXPRESSION",
  "HYPERGLANDULAR",
  "HYPERGRANULOSIS",
  "HYPERGRANULOTIC",
  "HYPERINFLAMED",
  "HYPERINFLAMMATORY",
  "HYPERINTENSE",
  "HYPERINTENSETICRY",
  "HYPERKERATOTIC",
  "HYPERLABELING",
  "HYPERLIPIDEMIA",
  "HYPERMATURATION",
  "HYPERMELANOTIC",
  "HYPERNEURY",
  "HYPERPIGMENTATION",
  "HYPERPIGMENTED",
  "HYPERPLASTIC",
  "HYPERPROLIFERATIVE",
  "HYPERVASCULAR",
  "HYPERVASCULARITY",
  "HYPERVASCULARIZED",
  "HYPOCELLULAR",
  "HYPOCELLULARITY",
  "HYPODUCTAL",
  "HYPOGRANULOSIS",
  "HYPOGRanulosis",
  "HYPOINFLAMMATORY",
  "HYPOKERATOSIS",
  "HYPOLIPOCYTIC",
  "HYPOMELANOTIC",
  "HYPOPIGMENTATION",
  "HYPOPIGMENTED",
  "HYPOTHENAR",
  "Headington",
  "Hibbard",
  "Histopathologically",
  "Hypermaturation",
  "ICD",
  "IDH1",
  "IDN",
  "IFC",
  "IGA",
  "IHC",
  "ILVEN",
  "IMIQUIMOD",
  "IMMUNOBULLOUS",
  "IMMUNOCYTOMA",
  "IMMUNOFLUORESCENCE",
  "IMMUNOHISTOCHEMICAL",
  "IMMUNOHISTOCHEMICALLY",
  "IMMUNOHISTOCHEMISTRY",
  "IMMUNOLABELING",
  "IMMUNONEGATIVITY",
  "IMMUNOPEROXIDASE",
  "IMMUNOPHENOTYPE",
  "IMMUNOPHENOTYPIC",
  "IMMUNOPHENOTYPICALLY",
  "IMMUNOPHENOTYPING",
  "IMMUNOPOSITIVITY",
  "IMMUNOREACTIVITY",
  "IMMUNOSTAINING",
  "IMMUNOSTAINS",
  "IMMUNOVESICULAR",
  "IMPETIGINIZATION",
  "IMPETIGINIZED",
  "IMT",
  "INACTIVATED",
  "INACTIVATed",
  "INCISIONAL",
  "INCISURA",
  "INFRAMAMMARY",
  "INFRAORBITAL",
  "INFRATIP",
  "INFUNDIBULA",
  "INFUNDIBULAR",
  "INFUNDIBULOCYSTIC",
  "INFUNDIBULOMA",
  "INFUNDIBULOMASTIC",
  "INSPISSATED",
  "INTERFOLLICULAR",
  "INTERKERATINOCYTE",
  "INTERPHALANGEAL",
  "INTERSTITIUM",
  "INTERTRAGICA",
  "INTERTRIGO",
  "INTRAADNEXAL",
  "INTRACAPSULAR",
  "INTRACORNEAL",
  "INTRACYTOPLASMIC",
  "INTRAEPIDERMAL",
  "INTRAEPIDERMALLY",
  "INTRAFOLLICULAR",
  "INTRAGLANDULAR",
  "INTRALUMINAL",
  "INTRALYMPHATIC",
  "INTRANEVIC",
  "INTRANODAL",
  "INTRASPINOUS",
  "INTRATUMORAL",
  "INVOLUTING",
  "IRRODERMA",
  "ISH",
  "ISK",
  "IWEI",
  "IgA",
  "Immunohistochemical",
  "Immunophenotypically",
  "Immunophenotyping",
  "Irr",
  "Iwei",
  "JADASSOHN",
  "JAMA",
  "JUHL",
  "JXG",
  "Jadassohn",
  "Jorizzo",
  "Juhl",
  "KAMINO",
  "KELOIDAL",
  "KEMPS",
  "KERATINIZATION",
  "KERATINOCYTE",
  "KERATINOCYTES",
  "KERATINOCYTIC",
  "KERATINOCYTOTOXICITY",
  "KERATOACANTHOMA",
  "KERATOACANTHOMAS",
  "KERATOACANTHOMATOUS",
  "KERATODERMA",
  "KERATOTIC",
  "KHOURDAJI",
  "KI67",
  "KOEBNERIZATION",
  "KOEBNERIZED",
  "KOILOCYTIC",
  "KOILOCYTOSIS",
  "KWOLEK",
  "Ka-like",
  "Kamino",
  "Kemps",
  "Khourdaji",
  "Ki67",
  "Kwolek",
  "L1-2",
  "LABIALIS",
  "LAMELLATION",
  "LATEROTHORACIC",
  "LCV",
  "LCV1",
  "LEF1",
  "LEIOMYOCYTES",
  "LEIOMYOMA",
  "LEIOMYOSARCOMA",
  "LENTIGINES",
  "LESIONAL",
  "LEUKOCYTOCLASIS",
  "LEUKOCYTOCLASTIC",
  "LEV",
  "LEV2",
  "LEV3",
  "LEV7",
  "LICHENIFICATION",
  "LICHENIFIED",
  "LICHENOID",
  "LICHENOIDES",
  "LIPIDIZATION",
  "LIPIDIZED",
  "LIPOATROPHY",
  "LIPOBLASTS",
  "LIPOCYTE",
  "LIPOCYTES",
  "LIPOCYTIC",
  "LIPODERMATOSCLEROSIS",
  "LIPOGRANULOMA",
  "LIPOGRANULOMATOUS",
  "LIPOIDICA",
  "LIPOMATOSUS",
  "LIPOMATOUS",
  "LIPOMEMBRANOUS",
  "LIVEDO",
  "LMM",
  "LN2",
  "LOBULATED",
  "LOCULATED",
  "LPLK",
  "LPP",
  "LSA",
  "LSC",
  "LYMPHADENOMA",
  "LYMPHANGIECTASIA",
  "LYMPHANGIOMA",
  "LYMPHANGIOMATOUS",
  "LYMPHANGIOSARCOMA",
  "LYMPHEDEMA",
  "LYMPHOCYTIC",
  "LYMPHOHISTIOCYTIC",
  "LYMPHOMATOID",
  "LYMPHOPLASMACYTIC",
  "LYMPHOPROLIFERATIVE",
  "LYP",
  "Leukocytoclastic",
  "Lev2",
  "Lev3",
  "Lev7",
  "Lipidized",
  "Lipoblasts",
  "Livedo",
  "MACROARCHITECTURE",
  "MACROCYSTIC",
  "MACROCYSTS",
  "MACROMELANOSOMES",
  "MACULARIS",
  "MACULOPAPULE",
  "MAJOCCHI",
  "MAJORA",
  "MAJUS",
  "MALASSEZIA",
  "MALIG",
  "MALIGNA",
  "MAML2",
  "MAMMILLATED",
  "MAP3K8",
  "MARGINATION",
  "MARINUM",
  "MARKKU",
  "MART1",
  "MART1CYTOMA",
  "MASGARI",
  "MASSON",
  "MASTOCYTOMA",
  "MASTOCYTOSIS",
  "MATRICAL",
  "MATRICOBLASTIC",
  "MATRICOBLASTS",
  "MATRICOMA",
  "MCC",
  "MCCALMONT",
  "MDM2",
  "MEF2C-SS18",
  "MEGAKARYOBLASTIC",
  "MELAN",
  "MELAN-A",
  "MELANIZATION",
  "MELANOACANTHOMA",
  "MELANOCYTE",
  "MELANOCYTES",
  "MELANOCYTIC",
  "MELANOCYTOMA",
  "MELANOCYTOMAS",
  "MELANONYCHIA",
  "MELANOPHAGES",
  "MENINGOCELE",
  "MENINGOTHELIAL",
  "MESENCHYMAL",
  "METACARPOPHALANGEAL",
  "METACHROMASIA",
  "METAPLASTIC",
  "METAPLASTICIA",
  "METATYPICAL",
  "MIBELLI",
  "MICACEOUS",
  "MICROARRAY",
  "MICROCALCIFICATION",
  "MICROCALIFICATION",
  "MICROCLONAL",
  "MICROCYST",
  "MICROCYSTIC",
  "MICROCYSTS",
  "MICROFOCAL",
  "MICROGRAPHIC",
  "MICROINVASIVE",
  "MICROMETASTATIC",
  "MICRONESTED",
  "MICRONODULAR",
  "MICRONODULES",
  "MICROPAPULAR",
  "MICROSATELLITE",
  "MICROSATELLITOSIS",
  "MICROSECRETORY",
  "MICROSTAGE",
  "MICROSTAGING",
  "MICROTUBULAR",
  "MICROVENULAR",
  "MICROVESICULATION",
  "MIDBACK",
  "MIDCHEEK",
  "MIDFOOT",
  "MIETTINEN",
  "MIGRANS",
  "MILIARIS",
  "MINOCYCLINE",
  "MINOCYCLINE-INDUCED",
  "MISAGO",
  "MISSENSE",
  "MITF",
  "MJ22",
  "MLH1",
  "MMIS",
  "MOHS",
  "MOL22",
  "MOLLUSCUM",
  "MONKEYPOX",
  "MONOABERRATION",
  "MONOCLONALITY",
  "MONOMOPHISM",
  "MONOMORPHOUS",
  "MORPHEA",
  "MORPHEAFORM",
  "MOSAICISM",
  "MPNST",
  "MSH2",
  "MSO22",
  "MSO23-2",
  "MTS",
  "MUC4",
  "MUCINOSA",
  "MUCINOSIS",
  "MUCINOUS",
  "MUCOCELE",
  "MUCOEPIDERMOID",
  "MUCOSITIS",
  "MULTICYSTIC",
  "MULTIFACTORIAL",
  "MULTIFILAMENT",
  "MULTIFOCAL",
  "MULTIFOCALITY",
  "MULTIFOCALLY",
  "MULTIFORME",
  "MULTINESTED",
  "MULTINODULAR",
  "MULTINUCLEATE",
  "MULTINUCLEATION",
  "MULTIPAPULAR",
  "MULTIPHASIC",
  "MULTITONED",
  "MUM1",
  "MUPIROCIN",
  "MYB",
  "MYELOCYTES",
  "MYELOFIBROSIS",
  "MYELOPEROXIDASE",
  "MYELOPROLIFERATIVE",
  "MYO5A",
  "MYO5A-NTRK3",
  "MYOEPITHELIAL",
  "MYOEPITHELIOMA",
  "MYOFIBROBLASTIC",
  "MYOFIBROCYTES",
  "MYOFIBROCYTIC",
  "MYOFIBROMA",
  "MYOID",
  "MYOINTIMAL",
  "MYOPERICYTES",
  "MYOPERICYTIC",
  "MYOPERICYTOMATOUS",
  "MYXEDEMATOSUS",
  "MYXOCOLLAGENOUS",
  "MYXOFIBROSARCOMA",
  "MYXOID",
  "MYXOINFLAMMATORY",
  "MZL",
  "Macrocysts",
  "Majocchi",
  "Malassezia",
  "Markku",
  "Masgari",
  "Masson",
  "McCalmont",
  "Melan",
  "Melan-A",
  "Melkersson-Rosenthal",
  "MiTF",
  "Mibelli",
  "Microcalcification",
  "Micronodular",
  "Miettinen",
  "Misago",
  "Mohs",
  "NAFTIN",
  "NAILBED",
  "NANOGEN",
  "NASOLABIAL",
  "NECROBIOSIS",
  "NEOGENOMICS",
  "NEOPLASIA",
  "NEOVASCULARIZATION",
  "NEPHROGENIC",
  "NEUHAUS",
  "NEUROCRISTIC",
  "NEURODERMATITIS",
  "NEUROENDOCRINE",
  "NEUROFIBROMA",
  "NEUROFIBROMATOUS",
  "NEUROFILAMENT",
  "NEUROID",
  "NEUROPATHICMA",
  "NEUROTHEKEOMAS",
  "NEUROTROPIC",
  "NEUROTROPISM",
  "NEUTROPHILIC",
  "NEVI",
  "NEVIC",
  "NEVOCELLULAR",
  "NEVi",
  "NFD",
  "NIGRICANS",
  "NITIDUS",
  "NK-cell",
  "NKI",
  "NLD",
  "NMSC",
  "NNGCT",
  "NODOSUM",
  "NODULARIS",
  "NODULARITY",
  "NODULOCYSTIC",
  "NON-EPIDERMOTROPIC",
  "NONDESIGNATING",
  "NONHEALING",
  "NONPRURITIC",
  "NONSCARRING",
  "NOSOLOGIC",
  "NOSOLOGICALLY",
  "NRAS",
  "NTRK",
  "NTRK1",
  "NTRK3",
  "NTRK3-MYO5A",
  "NUCLEOMEGALIC",
  "NUCLEOMEGALY",
  "NUE",
  "NUO",
  "NUSSBAUM",
  "NV",
  "Neogenomics",
  "Neuhaus",
  "Neurofilament",
  "Nonhealing",
  "Nosologically",
  "Nussbaum",
  "OAKS22",
  "ONCOCYTIC",
  "ONCOCYTOSIS",
  "ONCOPROGRESSION",
  "ONYCHOCYTIC",
  "ONYCHODYSTROPHY",
  "ONYCHOLYSIS",
  "ONYCHOMATRIXOMA",
  "ONYCHOMYCOSIS",
  "ORGANOID",
  "ORTHOHYPERKERATOSIS",
  "ORTHOKERATIN",
  "ORTHOKERATOSIS",
  "OSTEOCLASTIC",
  "OSTEOCYTES",
  "OSTIAL",
  "OVEREXPRESSION",
  "OVERSTAINED",
  "OVERSTAINING",
  "P16",
  "P40",
  "P53",
  "P63",
  "PACINIAN",
  "PAGETOID",
  "PALISADING",
  "PALLIDUM",
  "PALMOPLANTAR",
  "PANMEL",
  "PANNICULITIC",
  "PANNICULITIS",
  "PANNICULITic",
  "PANNICULUS",
  "PAPILLATED",
  "PAPILLATION",
  "PAPILLATIONS",
  "PAPILLIFERUM",
  "PAPILLOMATOSIS",
  "PAPILLOMATOUS",
  "PAPILLOMAVIRUS",
  "PAPULONODULAR",
  "PAPULONODULE",
  "PAPULOSIS",
  "PAPULOVESICULAR",
  "PARACORTICAL",
  "PARAFOLLICULAR",
  "PARAKERATOSIS",
  "PARAKERATOTIC",
  "PARAMEDIAN",
  "PARANEOPLASTIC",
  "PARANUCLEAR",
  "PARAPSORIASIS",
  "PARASEPTAL",
  "PARASPINAL",
  "PAROTIDEOMASSETERIC",
  "PASINI",
  "PATHOGENETIC",
  "PAUTRIER",
  "PAX5",
  "PAX8",
  "PAYOR",
  "PCP",
  "PCR",
  "PD1",
  "PEAU",
  "PECOMA",
  "PECOMAS",
  "PEComa",
  "PEComas",
  "PEDIS",
  "PEDUNCULATED",
  "PEM",
  "PEMPHIGOID",
  "PERIADNEXAL",
  "PERIANAL",
  "PERIAXILLARY",
  "PERIBIOPSY",
  "PERIBULBAR",
  "PERICAPSULAR",
  "PERICELLULAR",
  "PERICYSTIC",
  "PERICYTES",
  "PERIDUCTAL",
  "PERIECCRINE",
  "PERIFLEXURAL",
  "PERIFOLLICULAR",
  "PERIFOLLICULITIS",
  "PERIGENITAL",
  "PERIJUNCTIONAL",
  "PERILYMPHATIC",
  "PERIMUCOSAL",
  "PERINEURAL",
  "PERINEURIAL",
  "PERINEURIOMA",
  "PERINEURIOMA-LIKE",
  "PERINEURIOMATOUS",
  "PERIORAL",
  "PERIORIFICIAL",
  "PERITUMORAL",
  "PERIUMBILICAL",
  "PERIUNGUAL",
  "PERIVASCULAR",
  "PERNIOSIS",
  "PETECHIAL",
  "PHH3",
  "PHILTRUM",
  "PHOSPHOHISTONE",
  "PHOTOALLERGIC",
  "PHOTOALLERGY",
  "PHOTODERMATITIS",
  "PHOTODISTRIBUTED",
  "PHOTOEXACERBATED",
  "PHOTOTOXIC",
  "PHOTOTOXICITY",
  "PIERINI",
  "PIGMENTOSA",
  "PIGMENTOSUS",
  "PILAR",
  "PILARIS",
  "PILOMATRICAL",
  "PILOMATRICOMA",
  "PILOMATRICOMAS",
  "PILOMATRIXOMA",
  "PINKUS",
  "PIPA",
  "PITYRIASIFORM",
  "PITYRIASIS",
  "PITYROSPORON",
  "PLANA",
  "PLANAE",
  "PLANOPILARIS",
  "PLANUS",
  "PLANUS-LIKE",
  "PLASMACYTE-RICH",
  "PLASMACYTES",
  "PLASMACYTIC",
  "PLASMACYTOID",
  "PLEOMORPHIC",
  "PLEVA",
  "PMH",
  "PNI",
  "POIKILODERMA",
  "POIKILODERMATOUS",
  "POLIOSIS",
  "POLYANGIITIS",
  "POLYCLONAL",
  "POLYCLONALITY",
  "POLYOMAVIRUS",
  "POLYPLOIDY",
  "POLYTYPIA",
  "POROCARCINOMA",
  "POROID",
  "POROKERATOSIS",
  "POROKERATOTIC",
  "POROMA",
  "POROMAS",
  "POSTAURICULAR",
  "POSTINFLAMMATORY",
  "PRACT",
  "PRAME",
  "PRAME-negative",
  "PREAURICULAR",
  "PREAUTHORIZATION",
  "PREKERATOGENOUS",
  "PRETIBIAL",
  "PREVESICULAR",
  "PRKAR1A",
  "PRKAR1A-inactivated",
  "PRKC",
  "PRKCA",
  "PRKCB",
  "PRODROMAL",
  "PRODRUG",
  "PROFUNDA",
  "PROFUNDUS",
  "PROLIFERATIONS",
  "PROTUBERANS",
  "PRP",
  "PRURIGINIZED",
  "PRURITIC",
  "PSAMMOMA",
  "PSCN",
  "PSEUDOANGIOMATOUS",
  "PSEUDOANGIOSARCOMATOUS",
  "PSEUDOBRIDGING",
  "PSEUDOCAPSULE",
  "PSEUDOCARCINOMATOUS",
  "PSEUDOCHONDROID",
  "PSEUDOCYST",
  "PSEUDOCYSTIC",
  "PSEUDOCYSTS",
  "PSEUDOENCAPSULATED",
  "PSEUDOENCAPSULATION",
  "PSEUDOEPITHELIOMATOUS",
  "PSEUDOINFILTRATIVE",
  "PSEUDOKOILOCYTIC",
  "PSEUDOLUMINAL",
  "PSEUDOLYMPHOMA",
  "PSEUDOLYMPHOMATOUS",
  "PSEUDOMATURATION",
  "PSEUDOMATURE",
  "PSEUDOMATURING",
  "PSEUDOMOLLUSCOID",
  "PSEUDOMONAS",
  "PSEUDOMYOGENIC",
  "PSEUDONEST",
  "PSEUDONESTED",
  "PSEUDONESTING",
  "PSEUDONESTS",
  "PSEUDOPELADE",
  "PSEUDOPODS",
  "PSEUDOSARCOMATOID",
  "PSEUDOTUMOR",
  "PSEUDOVASCULAR",
  "PSEUDOVASCULARITY",
  "PSEUDOVERRUCOUS",
  "PSEUDOVESICULATED",
  "PSEUDOXANTHOMA",
  "PSORIASIFORM",
  "PT1A",
  "PT1AN1C",
  "PT2A",
  "PT2B",
  "PT3A",
  "PT3B",
  "PT4A",
  "PT4B",
  "PTLPD",
  "PTYCHOTROPICA",
  "PUNCTUM",
  "PURPURIC",
  "PUSTULATION",
  "PUSTULOSIS",
  "PXE",
  "PXE-like",
  "PXELPDE",
  "PYKNOTIC",
  "PYODERMA",
  "PYOGENIC",
  "Pacinian",
  "PanMel",
  "Pasini",
  "Pautrier",
  "Pedis",
  "Perineural",
  "Phosphohistone",
  "Pierini",
  "Pilomatricoma",
  "Pinkus",
  "Plasmacytes",
  "Poliosis",
  "Pract",
  "Pre-Mohs",
  "Pseudomonas",
  "Pseudonesting",
  "Pustulosis",
  "QUADRISECTED",
  "QUINTISECTED",
  "RAGHAVAN",
  "RARIFICATION",
  "RCC",
  "REBIOPSY",
  "RECANALIZATION",
  "RECANALIZING",
  "RECUTS",
  "REEXCISION",
  "REEXCISIONS",
  "REFRACTILE",
  "RENIFORM",
  "REQUENA",
  "RESTAIN",
  "RESTAINING",
  "RETIA",
  "RETICULOHISTIOCYTOMA",
  "RHUS",
  "ROS1",
  "ROSACEA",
  "ROSEA",
  "ROSEBERRY",
  "RPR",
  "RREB1",
  "RUBRA",
  "Raghavan",
  "Requena",
  "Rosai-Dorfman",
  "Roseberry",
  "S100",
  "S22-13468",
  "SANGUEZA",
  "SARCOIDAL",
  "SARCOIDOSIS",
  "SARCOMATOID",
  "SARCOMAtoid",
  "SAUCERIZATION",
  "SBCC",
  "SCAPHA",
  "SCC",
  "SCCIS",
  "SCHWANNIAN",
  "SCHWANNOMA",
  "SCHWOB",
  "SCLEREDEMA",
  "SCLEROMYXEDEMA",
  "SCLEROSING",
  "SCLEROSUS",
  "SD22",
  "SEB",
  "SEBACEOMA",
  "SEBACEUS",
  "SEBOBLASTIC",
  "SEBOBLASTS",
  "SEBOCYTES",
  "SEBOCYTIC",
  "SEPTAE",
  "SEPTATIONS",
  "SEROSANGUINOUS",
  "SERPIGINOUS",
  "SEZARY",
  "SHIELL",
  "SIDEROPHAGES",
  "SIDEROTIC",
  "SITU",
  "SITu",
  "SLNB",
  "SMA",
  "SMOP",
  "SMPTCLPD",
  "SOLARIS",
  "SOX10",
  "SOYER",
  "SPILUS",
  "SPINOSUM",
  "SPIRADENOCARCINOMA",
  "SPIRADENOCYLINDROMA",
  "SPIRADENOCYLINDROMATOUS",
  "SPIRADENOMA",
  "SPITZ",
  "SPITZOID",
  "SPONGIOSIS",
  "SPONGIOTIC",
  "SPOROTRICHOID",
  "SPOROTRICHOSIS",
  "SQUAMOID",
  "SQUAMOTIZATION",
  "STAT6",
  "STEATOCYSTOMA",
  "STELAE",
  "STOREY",
  "STRIATUS",
  "STROMA",
  "STROMAL",
  "STROMAPONENTS",
  "SUBCATEGORIZATION",
  "SUBCLONE",
  "SUBCOMPONENTS",
  "SUBCORNEAL",
  "SUBCUTIS",
  "SUBDERMAL",
  "SUBEPIDERMAL",
  "SUBEPIDERMALL",
  "SUBJUNCTIONAL",
  "SUBMANDIBULAR",
  "SUBMENTAL",
  "SUBMUCOSAL",
  "SUBQ",
  "SUBTOTALLY",
  "SUBTYPING",
  "SUBUNGUAL",
  "SUBXIPHOID",
  "SUFUWARA",
  "SULZBERGER",
  "SUPERCLEAR",
  "SUPERFICIALIS",
  "SUPPURATIVA",
  "SUPRABASAL",
  "SUPRABROW",
  "SUPRAFOLLICULAR",
  "SUPRAPAPILLARY",
  "SUPRAPUBIC",
  "SUPRATIP",
  "SYNAPTOPHYSIN",
  "SYNCYTIA",
  "SYNCYTIAL",
  "SYNCYTIALLY",
  "SYPHILLIS",
  "SYRINGOCYSTADENOMA",
  "SYRINGOFIBROADENOMATOUS",
  "SYRINGOMA",
  "SYRINGOMAS",
  "SYRINGOMATOSIS",
  "SYRINGOMATOUS",
  "SYRINGOSQUAMOUS",
  "SYRINGOTROPIC",
  "SYRINGOTROPISM",
  "Sangueza",
  "Saucerization",
  "Schwob",
  "Seb",
  "Sezary",
  "SezaryOTROPISM",
  "Shabrawi-Caelen",
  "Shiell",
  "Sjogren's",
  "Soyer",
  "Storey",
  "SubQ",
  "Subungual",
  "Sugawara",
  "Sulzberger",
  "TAGUCHI",
  "TARDA",
  "TARGETOID",
  "TCK",
  "TELANGIECTASIA",
  "TELANGIECTATIC",
  "TELANGIESCTASIA",
  "TELOGEN",
  "TF",
  "TFI",
  "THROMBOSED",
  "TINGIBLE",
  "TMCCALMONT",
  "TODA",
  "TOKER",
  "TOPHACEOUS",
  "TOTALIS",
  "TOUTON",
  "TRANSECTED",
  "TRANSEPIDERMAL",
  "TRANsected",
  "TRAPEZIAL",
  "TRICHEPITHELIOMA",
  "TRICHILEMMAL",
  "TRICHILEMMOMA",
  "TRICHILEMMOMAS",
  "TRICHILEMMOMATOUS",
  "TRICHOADENOMA",
  "TRICHOBLASTIC",
  "TRICHOBLASTOMA",
  "TRICHODISCOMA",
  "TRICHOEPITHELIOMA",
  "TRICHOFOLLICULOMA",
  "TRICHOGERMINOMA",
  "TRICHOGERMINOMATOUS",
  "TRICHOHYALINE",
  "TRICHOMALACIA",
  "TRICHOTILLOMANIA",
  "TRICHOTILLOSIS",
  "TRIPHASIC",
  "TRK",
  "TRUNCAL",
  "TRYPTASE",
  "TSURUTA",
  "TTF1",
  "TUMORAL",
  "Taguchi",
  "Toda",
  "Toker",
  "Touton",
  "Touton-type",
  "Transepidermal",
  "Trichogerminoma",
  "Tsuruta",
  "UHLENHAKE",
  "ULCERONECROTIC",
  "ULTRASTRUCTURAL",
  "UMBILICATED",
  "UMBOLITH",
  "UNDESCRIBED",
  "UNGUIUM",
  "UNORIENTED",
  "UPREGULATION",
  "UROTHELIAL",
  "URTICARIAL",
  "URTICOGENIC",
  "Uhlenhake",
  "Umbolith",
  "V600E",
  "VARICEAL",
  "VASCULARE",
  "VASCULARIZED",
  "VASCULITIC",
  "VASCULITIS",
  "VASCULOPATHY",
  "VASOOCCLUSIVE",
  "VE1",
  "VEGETANS",
  "VELLUS",
  "VERRUCAL",
  "VERRUCIFORM",
  "VERRUCOUS",
  "VESICOPUSTULES",
  "VESICULOPUSTULAR",
  "VIKAS",
  "VIROPATHIC",
  "VRJTF",
  "VS22-2189",
  "VULGARIS",
  "VULVOCRURAL",
  "VVG",
  "Vikas",
  "Viropathic",
  "WEBSPACE",
  "WHORLING",
  "WINER",
  "WNT",
  "WNT-activated",
  "Winer",
  "Wnt",
  "Wnt-activated",
  "XANTHOGRANULOMA",
  "XANTHOGRANULOMAS",
  "XANTHOMA",
  "XANTHOMATOUS",
  "XIIIa",
  "YEH",
  "YLEE",
  "YOHN",
  "Yeh",
  "Yohn",
  "ZOSTERIFORM",
  "abd",
  "acantholysis",
  "acantholytic",
  "acanthoma",
  "acanthosis",
  "acanthotic",
  "acellularity",
  "achilles",
  "acneiform",
  "acral",
  "acroangiodermatitis",
  "acrochordons",
  "acrosyringeal",
  "acuminata",
  "acuminatum",
  "adenoLIPOMA",
  "adipocytic",
  "adipophilin",
  "adnexae",
  "adnexal",
  "adnexocentric",
  "adnexocentricity",
  "adnexotropism",
  "adventitial",
  "alopetic",
  "amelanotic",
  "amongstnetic",
  "amphophilic",
  "anetodorma",
  "angiocentricity",
  "angiofibromatous",
  "angiofibromatousa",
  "angiofibrotic",
  "angiohistiocytoma",
  "angiolipoma",
  "angiolipomatous",
  "angiolymphoid",
  "angiomatoid",
  "angiomatosis",
  "angiomyxoma",
  "angioplasia",
  "angiosarcoma",
  "annulare",
  "anthropod",
  "anti-rosacea",
  "anuclear",
  "aphthouse",
  "arborizing",
  "areata",
  "arthroplasty",
  "artifactually-distorted",
  "atopic",
  "atopy",
  "atrophicans",
  "atrophoderma",
  "atypia",
  "atypicality",
  "atypicals",
  "atypism",
  "autoeczematization",
  "autoinflammatory",
  "autosomal",
  "axonal",
  "bandlike",
  "bap1-inactivated",
  "basaloid",
  "biclonality",
  "biphasic",
  "blanchable",
  "blaschkitis",
  "blaschkoid",
  "blastic",
  "blenorrhagicum",
  "bowenoid",
  "bronchioloalveolar",
  "bronchogenic",
  "bullae",
  "bullous",
  "c-myc",
  "calcinosis",
  "caldesmon",
  "calponin",
  "candidiasis",
  "capecitabine",
  "capitis",
  "carcinoid",
  "catagen",
  "catalogued",
  "catenin",
  "cavum",
  "cd34",
  "cd8",
  "centrifugum",
  "cerebriformatitis",
  "cheilitis",
  "cholinergic",
  "chondroid",
  "chondroossification",
  "chromogranin",
  "chromomycosis",
  "chrondrodermatitis",
  "chrondroossification",
  "chronica",
  "chronicus",
  "cjs",
  "clasis",
  "clavicular",
  "clinicopathologic",
  "clonality",
  "clonally",
  "coexpress",
  "coexpresses",
  "coexpressing",
  "coexpression",
  "collagenous",
  "collarette",
  "columnella",
  "comedonal",
  "comedone",
  "comedone-like",
  "comedones",
  "confluently",
  "contagiosum",
  "cordlike",
  "corneocytes",
  "corneocytophagocysis",
  "corneocytophagocytosis",
  "corneum",
  "cornification",
  "cornified",
  "cornify",
  "cornifying",
  "cornoid",
  "corporis",
  "coverslip",
  "crateriform",
  "cruris",
  "cryopyrin-associated",
  "cuboidal",
  "cutanea",
  "cuticulated",
  "cuticulation",
  "cylindrocarcinoma",
  "cylindroma",
  "cylindrospiradenocarcinoma",
  "cystadenoma",
  "cytogenetic",
  "cytohistomorphologic",
  "cytokeratin",
  "cytokeratins",
  "cytologically",
  "cytomegalic",
  "cytomegaly",
  "cytomorphologic",
  "cytomorphologically",
  "cytomorphologies",
  "cytomorphology",
  "cytopathic",
  "cytopathologic",
  "cytotoxicity",
  "d'orange",
  "ddx",
  "de",
  "debulk",
  "debulked",
  "debulking",
  "degranulated",
  "degranulation",
  "demodecidosis",
  "dendrocytes",
  "depigmented",
  "derm",
  "dermatofibroma",
  "dermatofibroma-like",
  "dermatofibromas",
  "dermatofibrosarcoma",
  "dermatoheliosis",
  "dermatoheliotic",
  "dermatomyofibroma",
  "dermatomyofibromas",
  "dermatomyositis",
  "dermatopathologic",
  "dermatopathologist",
  "dermatopathologists",
  "dermatopathology",
  "dermatophytic",
  "dermatophytosis",
  "dermoepidermal",
  "dermopathy",
  "dermoscopic",
  "dermoscopy",
  "desmin",
  "desmoplasiam",
  "desmoplastic",
  "digitated",
  "disseminatus",
  "diutinum",
  "doxycycline",
  "ductal",
  "ductular",
  "dyschromic",
  "dyschromicum",
  "dyshidrosiform",
  "dyshidrosis",
  "dyskeratomas",
  "dyskeratotic",
  "dysmaturation",
  "dysp",
  "dyspigmentation",
  "ectatic",
  "ectopy",
  "elasticum",
  "elasticum-like",
  "elastolysis",
  "elastosis",
  "elastotic",
  "elevatum",
  "emperipolesis",
  "endophytic",
  "epidermodysplasia",
  "epidermolytic",
  "epidermotropic",
  "epidermotropism",
  "epigastric",
  "epithelioid",
  "epitheliotropism",
  "erythematosus",
  "erythematosus-like",
  "erythematous",
  "erythrasma",
  "erythroderma",
  "erythrodermic",
  "erythrodysesthesia",
  "etiopathogenesis",
  "evaluable",
  "exanthem",
  "excisional",
  "exocytosis",
  "exocytotic",
  "exoendophytic",
  "exophytic",
  "extracutaneous",
  "extrafacial",
  "extragenital",
  "extramammary",
  "extranodal",
  "facieie",
  "fascial",
  "fibrillar",
  "fibroadenoma",
  "fibroblastoma",
  "fibrocollagenous",
  "fibrocystic",
  "fibrocytes",
  "fibroepithelial",
  "fibroepitheliomatous",
  "fibrofatty",
  "fibrofolliculoma",
  "fibrohistiocytic",
  "fibrolipoma",
  "fibromas",
  "fibromyxoid",
  "fibroosseous",
  "fibroplasia",
  "fibrosarcomatous",
  "fibrosing",
  "fibroxanthoma",
  "fissuratum",
  "fluctuance",
  "foliaceus",
  "folliculitis",
  "folliculocentric",
  "folliculocentricity",
  "folliculosebaceous",
  "folliculotropic",
  "folliculotropism",
  "fungoides",
  "fusifor",
  "gammopathy",
  "gangrenosum",
  "generis",
  "genodermatosis",
  "genotyping",
  "germline",
  "glioblastoma",
  "glomangiosarcoma",
  "glomeruloid",
  "glomus",
  "granulomatosis",
  "granulomatous",
  "granulosum",
  "grey",
  "grey-brown",
  "halogenoderma",
  "hamartoma",
  "hamartoma-like",
  "helicis",
  "hemangioendothelioma",
  "hematolymphoid",
  "hematolymphoproliferative",
  "hemosiderin",
  "hemosiderophages",
  "hemosiderosis",
  "hemosiderotic",
  "hepatobiliary",
  "herpesviral",
  "heterogenous",
  "hidradenitis",
  "hidradenocarcinoma",
  "hidradenoma",
  "hidradenomas",
  "hidroacanthoma",
  "histiocytic",
  "histiocytoid",
  "histiocytoma",
  "histiocytosis",
  "histomorphologic",
  "histomorphologically",
  "histomorphology",
  "histopathologic",
  "histopathologically",
  "hyalinized",
  "hyalinizing",
  "hyerp",
  "hypercellular",
  "hypercellularity",
  "hyperchromasia",
  "hyperchromatic",
  "hypercoagulability",
  "hypercoagulable",
  "hypercoagulation",
  "hyperconvoluted",
  "hypereosinophilia",
  "hypereosinophilic",
  "hyperexpression",
  "hypergranulosis",
  "hypergranulotic",
  "hyperinflamed",
  "hyperinflammatory",
  "hyperintense",
  "hyperkeratotic",
  "hyperlabeling",
  "hyperlipidemia",
  "hypermaturation",
  "hypermelanotic",
  "hyperpigmentation",
  "hyperpigmented",
  "hyperplastic",
  "hyperproliferative",
  "hypervascular",
  "hypervascularity",
  "hypervascularized",
  "hypocellular",
  "hypocellularity",
  "hypoductal",
  "hypoinflammatory",
  "hypokeratosis",
  "hypolipocytic",
  "hypopigmentation",
  "hypopigmented",
  "hypothenar",
  "imiquimod",
  "immunobullous",
  "immunocytoma",
  "immunofluorescence",
  "immunohistochemical",
  "immunohistochemically",
  "immunohistochemistry",
  "immunolabeling",
  "immunonegativity",
  "immunoperoxidase",
  "immunoperoxidase-stained",
  "immunophenotype",
  "immunophenotypic",
  "immunophenotyping",
  "immunopositivity",
  "immunoreactivity",
  "immunostain",
  "immunostained",
  "immunostaining",
  "immunostains",
  "immunovesicular",
  "impetiginization",
  "impetiginized",
  "incisional",
  "incisura",
  "inframammary",
  "infraorbital",
  "infratip",
  "infundibula",
  "infundibular",
  "infundibulocystic",
  "infundibuloma",
  "inspissated",
  "interfollicular",
  "interkeratinocyte",
  "interphalangeal",
  "interstitium",
  "intertragica",
  "intertrigo",
  "intraadnexal",
  "intracapsular",
  "intracorneal",
  "intracytoplasmic",
  "intraepidermal",
  "intraepidermally",
  "intrafollicular",
  "intraglandular",
  "intraluminal",
  "intralymphatic",
  "intranevic",
  "intranodal",
  "intraspinous",
  "intratumoral",
  "involuting",
  "iododerma",
  "keloidal",
  "keratinocyte",
  "keratinocyte-derived",
  "keratinocytes",
  "keratinocytic",
  "keratoacanthoma-like",
  "keratoacanthomaicity",
  "keratoacanthomas",
  "keratoacanthomatous",
  "keratoderma",
  "keratotic",
  "koebnerization",
  "koebnerized",
  "koilocytic",
  "koilocytosis",
  "labialis",
  "lamellation",
  "laterothoracic",
  "leiomyocytes",
  "leiomyoma",
  "leiomyosarcoma",
  "lesional",
  "leuko",
  "leukocytoc",
  "levclasis",
  "lichenification",
  "lichenified",
  "lichenoid",
  "lichenoides",
  "lipidization",
  "lipidized",
  "lipoatrophy",
  "lipocyte",
  "lipocyte-like",
  "lipocytestosclerosis",
  "lipogranuloma",
  "lipogranulomatous",
  "lipoidica",
  "lipomatosus",
  "lipomatous",
  "lipomembranous",
  "livedo",
  "lobulated",
  "loculated",
  "lymphadenomarcoma",
  "lymphedemama",
  "lymphocytic",
  "lymphohistiocytic",
  "lymphomatoid",
  "lymphoplasmacytic",
  "lymphoproliferative",
  "macroarchitecture",
  "macrocystic",
  "macromelanosomes",
  "macularis",
  "maculopapule",
  "majora",
  "majus",
  "malig",
  "maligna",
  "mammillated",
  "margination",
  "marinum",
  "mastocytoma",
  "mastocytosis",
  "matricallastic",
  "matricoblasts",
  "matricoma",
  "megakaryoblastic",
  "melanization",
  "melanoacanthoma",
  "melanocyte",
  "melanocytes",
  "melanocytic",
  "melanocytoma",
  "melanocytomas",
  "melanonychia",
  "melanophage-rich",
  "melanophages",
  "meningocele",
  "meningothelial",
  "metacarpophalangeal",
  "metachromasia",
  "metaplastic",
  "metatypical",
  "micaceous",
  "microarray",
  "microcalcification",
  "microcalification",
  "microclonal",
  "microcyst",
  "microcystic",
  "microcysts",
  "microfocal",
  "micrographic",
  "microinvasive",
  "micrometastatic",
  "micronested",
  "micronodular",
  "micronodules",
  "micropapular",
  "microsatellite",
  "microsatellitosis",
  "microsecretory",
  "microstage",
  "microstaging",
  "microtubular",
  "microvenular",
  "microvesiculation",
  "midback",
  "midcheek",
  "midfoot",
  "migrans",
  "missense",
  "monoaberratio",
  "monomophism",
  "monomorphism",
  "monomorphous",
  "morphea",
  "morpheaform",
  "mosaicism",
  "mucinosa",
  "mucinosis",
  "mucoceletic",
  "mucoepidermoid",
  "multifactorial",
  "multifilament",
  "multifocality",
  "multifocally",
  "multiforme",
  "multiforme-like",
  "multinested",
  "multinodular",
  "multinucleate",
  "multinucleation",
  "multiphasicdase",
  "mupirocinlar",
  "my",
  "myelofibro",
  "myoepithelialative",
  "myoepithelioma",
  "myoepithelium",
  "myofibroblastic",
  "myofibrocytes",
  "myofibrocytic",
  "myofibroma",
  "myoid",
  "myointimal",
  "myopericyticus",
  "myxedematosu",
  "myxofibrosarcoma",
  "myxoid",
  "myxoinflammatory",
  "n",
  "naftin",
  "nailbed",
  "nanogen",
  "neovascular",
  "nephrogenic",
  "neurocristic",
  "neurod",
  "neuroendoc",
  "neurofibroma",
  "neurofibroma-like",
  "neurofibromatous",
  "neurofilament",
  "neuroid",
  "neuropathic",
  "neurothekeoma",
  "neurothekeomas",
  "neurotropic",
  "neurotropism",
  "neutrophilic",
  "nevic",
  "nigrica",
  "nodosum",
  "nodularis",
  "nodularis-like",
  "nodularity",
  "nodulocystic",
  "non-condylomatous",
  "non-epidermotropic",
  "non-histiocytic",
  "non-melanocytic",
  "non-mucinous",
  "non-neurocristic",
  "non-prodcutive",
  "nondesignating",
  "nonhealing",
  "nonscarri",
  "nuc",
  "nucleomeg",
  "oid-oid",
  "oncocytic",
  "oncocytosis",
  "oncoprogression",
  "onychocytic",
  "onychodystrophy",
  "onycholysis",
  "onychomatrixoma",
  "onychomycosis",
  "organoid",
  "orthohyperkeratosis",
  "orthokeratin",
  "osteoclasticis",
  "ostialaine",
  "overexpression",
  "p16staining",
  "p40-negative",
  "p53",
  "p63",
  "pT1a",
  "pT1b",
  "pT2aN1c",
  "pT2b",
  "pT3a",
  "pT3b",
  "pT4asading",
  "pan-TRKlitis",
  "panniculitic",
  "panniculitis-like",
  "panniculus",
  "papillated",
  "papillation",
  "papillations",
  "papillomatosis",
  "papillomatous",
  "papillomavirus",
  "papulonodular",
  "papulonodulear",
  "papulovesicularpulosis",
  "parakeratosis",
  "parakeratotic",
  "paramedian",
  "paraneoplastic",
  "paranuclear",
  "parapsoriasis",
  "parapsoriasis-like",
  "paraseptal",
  "paraspinal",
  "parotideomasseteric",
  "pathogenetic",
  "payor",
  "pcp",
  "peau",
  "pedunculated",
  "pemphigoid",
  "peri-infundibular",
  "periFOLLICULITIS",
  "periadnexal",
  "perianal",
  "periaxillary",
  "peribiopsy",
  "peribulbar",
  "pericapsular",
  "pericellular",
  "pericystic",
  "pericytes",
  "periductal",
  "perieccrine",
  "periflexural",
  "perifollicular",
  "perifolliculitis",
  "perigenital",
  "perijunctional",
  "perilymphatic",
  "perimucosal",
  "perineural",
  "perineurial",
  "perineurioma",
  "perineurioma-like",
  "perineuriomatous",
  "perineurionma-like",
  "perioral",
  "periorificial",
  "peritumoral",
  "periumbilical",
  "periungual",
  "perivascular",
  "perniosis",
  "perniosis-like",
  "perstans",
  "petechial",
  "philtrum",
  "phosphohistone",
  "phosphohistone-H3",
  "phosphophistone-H3",
  "photoallergic",
  "photoallergy",
  "photodermatitis",
  "photodistributed",
  "photoexacerbated",
  "phototoxic",
  "phototoxicity",
  "pigmentosa",
  "pigmentosus",
  "pilar",
  "pilaris",
  "pilomatrical",
  "pilomatricoma",
  "pilomatricomas",
  "pilomatrixoma",
  "pityriasiform",
  "pityriasis",
  "pityrosporon",
  "plana",
  "planae",
  "planopilaris",
  "planus",
  "planus-like",
  "plasmacyte-rich",
  "plasmacytes",
  "plasmacytic",
  "plasmacytoid",
  "pleomorphic",
  "poikiloderma",
  "poikilodermatous",
  "polyangiitis",
  "polyclonal",
  "polyclonality",
  "polyomavirus",
  "polyploidy",
  "polytypia",
  "porocarcinoma",
  "poroid",
  "porokeratosis",
  "porokeratotic",
  "poroma",
  "poroma-like",
  "poromas",
  "post-infalmmatory",
  "post-irriadiation",
  "postauricular",
  "postinflammatory",
  "prame",
  "preauricular",
  "preauthorization",
  "pretibialgenous",
  "prevesicular",
  "prodromal",
  "prodrug",
  "profunda",
  "profundus",
  "proliferations",
  "protuberans",
  "pruriginized",
  "pruritic",
  "psammoma",
  "pseudoangiomatous",
  "pseudoangiosarcomatous",
  "pseudobridging",
  "pseudocapsule",
  "pseudocarcinomatous",
  "pseudochondroid",
  "pseudocyst",
  "pseudocystic",
  "pseudocysts",
  "pseudoencapsulated",
  "pseudoencapsulation",
  "pseudoepitheliomatous",
  "pseudoinfiltrative",
  "pseudokoilocytic",
  "pseudoluminal",
  "pseudolymphoma",
  "pseudolymphoma-associated",
  "pseudolymphoma-like",
  "pseudolymphomatous",
  "pseudolympnomatous",
  "pseudomaturation",
  "pseudomature",
  "pseudomaturing",
  "pseudomolluscoid",
  "pseudomyogenic",
  "pseudonest",
  "pseudonested",
  "pseudonests",
  "pseudopelade",
  "pseudopods",
  "pseudosarcomatoid",
  "pseudotumor",
  "pseudovascular",
  "pseudovascularity",
  "pseudoverrucous",
  "pseudovesiculated",
  "pseudoxanthoma",
  "psoriasiform",
  "ptychotropica",
  "punctum",
  "purpuric",
  "pustulation",
  "pustulosis",
  "pyknotic",
  "pyoderma",
  "pyogenic",
  "quadrisected",
  "quintisected",
  "rarification",
  "rebiopsy",
  "recanalization",
  "recanalizing",
  "recuts",
  "reexcision",
  "reexcisions",
  "refractile",
  "reniform",
  "restain",
  "restaining",
  "retia",
  "reticulohistiocytoma",
  "rosacea",
  "rosacea-like",
  "rosea",
  "rubra",
  "sarcoidal",
  "sarcoidosis",
  "sarcomatoid",
  "scapha",
  "schwannian",
  "schwannoma",
  "scleredema",
  "scleromyxedema",
  "scleromyxedema-like",
  "sclerosing",
  "sclerosus",
  "seb",
  "sebaceoma",
  "sebaceus",
  "seboblast-rich",
  "seboblastic",
  "seboblasts",
  "sebocytes",
  "sebocytic",
  "septae",
  "septations",
  "serosanguinous",
  "serpiginous",
  "sezary",
  "siderophages",
  "spilus",
  "spilus-like",
  "spinosum",
  "spiradenocarcinoma",
  "spiradenocylindromatous",
  "spiradenoma",
  "spitz",
  "spitzoid",
  "spongiosis",
  "spongiosis-like",
  "spongiotic",
  "sporotrichoid",
  "sporotrichosis",
  "squamoid",
  "squamotization",
  "steatocystoma",
  "stelae",
  "striatus",
  "stroma",
  "stromal",
  "subcategorization",
  "subclone",
  "subcomponents",
  "subcorneal",
  "subcutis",
  "subdermal",
  "subepidermal",
  "subjunctional",
  "submandibular",
  "submental",
  "submucosa",
  "submucosal",
  "subtotally",
  "subtyping",
  "subungual",
  "subxiphoid",
  "superclear",
  "superficialis",
  "suppurativa",
  "suprabrow",
  "suprafollicular",
  "suprapapillary",
  "suprapubic",
  "supratip",
  "synaptophysin",
  "syncytia",
  "syncytial",
  "syncytially",
  "syphillis",
  "syringeal",
  "syringocystadenoma",
  "syringocystadenoma-like",
  "syringofibroadenoma-like",
  "syringoma",
  "syringoma-like",
  "syringomas",
  "syringomatosis",
  "syringomatous",
  "syringosquamous",
  "syringotropic",
  "syringotropism",
  "tarda",
  "tardive",
  "targetoid",
  "telangiectasia",
  "telangiectatic",
  "telangiesctasia",
  "telogen",
  "tf",
  "thrombosed",
  "tingible",
  "tmccalmont",
  "tophaceous",
  "totalis",
  "transepidermal",
  "trapezial",
  "trichepithelioma",
  "trichilemmal",
  "trichilemmoma",
  "trichilemmoma-like",
  "trichilemmomas",
  "trichilemmomatous",
  "trichoadenoma",
  "trichoblastic",
  "trichoblastoma",
  "trichodiscoma",
  "trichoepithelioma",
  "trichofolliculoma",
  "trichogerminoma-like",
  "trichogerminomatous",
  "trichohyaline",
  "trichohyaline-like",
  "trichomalacia",
  "trichotillomania",
  "trichotillosis",
  "triphasic",
  "truncal",
  "tryptase",
  "ulceronecrotic",
  "ultrastructural",
  "umbilicated",
  "undescribed",
  "unguium",
  "unoriented",
  "upregulation",
  "urothelial",
  "urticarial",
  "urticogenic",
  "vaculopathy",
  "variceal",
  "vasculare",
  "vascularized",
  "vasculitic",
  "vasculitis",
  "vasculitis-associated",
  "vasculitis-like",
  "vasculopathy",
  "vasoocclusive",
  "vegetans",
  "vellus",
  "vellus-sized",
  "verrucal",
  "verruciform",
  "verruciformis-like",
  "verrucous",
  "vesicopustules",
  "vrjtf",
  "vulgaris",
  "vulvocrural",
  "webspace",
  "whorling",
  "xanthogranuloma",
  "xanthogranuloma-like",
  "xanthogranulomas",
  "xanthoma",
  "xanthoma-like",
  "xanthomatous",
  "ylee",
  "zosteriform"
];
