<template>
  <div class="container">
    <macro-enabled-editor v-show="false" name="" value="" />
    <h2 class="mb-3">Copy User Dictionary</h2>
    <button class="btn btn-primary mb-2" @click="isExtractMacroWordsOpen = true">
      Extract words from macros
    </button>
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col">
          <text-input
            label="User ID for source dictionary"
            v-model="sourceId"
            placeholder="Copying from..."
            :disabled="hasExtractedWords"
          />
          <b class="ml-1">
            {{
              hasExtractedWords
                ? "Words in text box will be used."
                : sourceId.length
                ? "Dictionary Name: " + generateDictionaryName(sourceId)
                : "Uses default dictionary if no source ID entered"
            }}
          </b>
        </div>
        <div class="col">
          <text-input
            label="User ID for target dictionary"
            v-model="targetId"
            placeholder="Copying to..."
          />
          <b class="ml-1">
            {{
              targetId.length
                ? "Dictionary Name: " + generateDictionaryName(targetId)
                : "Please enter target user ID."
            }}
          </b>
        </div>
      </div>
      <text-area-input class="mt-5" label="Words to Add" v-model="extractedWords" />
      <div class="d-flex justify-content-end p-1">
        <button
          class="btn btn-primary"
          v-shortkey="submitShortkey"
          @shortkey="handleSubmit"
          :disabled="!targetId || isRunning"
          type="submit"
        >
          Submit
        </button>
      </div>
      <div class="row" v-if="isRunning">
        <div class="col-3">
          {{ message }}
        </div>
        <div class="col-3">{{ currentIndex }} of {{ totalPending }}</div>
      </div>
      <div v-if="failedWords.length" class="mt-2">
        The following words could not be added:
        <div>
          {{ failedWords.join(", ") }}
        </div>
      </div>
    </form>
    <modal :status="isExtractMacroWordsOpen" @close="isExtractMacroWordsOpen = false">
      <h2>Extract Words From Result Macros</h2>
      <!-- <select-input label="Lab" :items="availableLabs" v-model="labToExtract" /> -->
      <tag-input
        label="Users"
        :dataSource="userOptions"
        :displayExpr="userDisplayName"
        valueExpr="id"
        v-model="usersToExtract"
      />
      <submit-cancel-row
        @submit="extractWordsFromMacros"
        @cancel="isExtractMacroWordsOpen = false"
        :isDisabled="!usersToExtract.length"
      />
    </modal>
  </div>
</template>

<script>
import TextInput from "./common/TextInput.vue";
import MacroEnabledEditor from "./common/MacroEnabledEditor.vue";
import mccalmontWordList from "@/modules/mccalmontWordList";
import { altKey } from "@/modules/helpers";
import TextAreaInput from "./TextAreaInput.vue";
import { MacrosApi, UsersApi } from "@/services";
import Modal from "./common/Modal.vue";
// import SelectInput from "./common/SelectInput.vue";
import { mapState } from "vuex";
import DataSource from "devextreme/data/data_source";
import TagInput from "./common/TagInput.vue";
import { UserTypesEnum } from "@/modules/enums";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";

export default {
  components: { TextInput, MacroEnabledEditor, TextAreaInput, Modal, TagInput, SubmitCancelRow },
  data() {
    return {
      sourceId: "",
      targetId: "",
      isRunning: false,
      message: "",
      currentIndex: null,
      totalPending: null,
      successfulWordCount: 0,
      failedWordCount: 0,
      failedWords: [],
      submitShortkey: altKey("s"),
      extractedWords: "",
      isExtractMacroWordsOpen: false,
      usersToExtract: [],
      labToExtract: null
    };
  },
  mounted() {
    this.labToExtract = this.currentLab;
  },
  methods: {
    async handleSubmit() {
      if (this.isRunning) {
        return;
      }
      if (!this.targetId) {
        window.alert("Please enter a target user ID.");
        return;
      }
      this.isRunning = true;
      this.resetCount();
      this.message = "Copy started";
      const sourceDictName = this.generateDictionaryName(this.sourceId);
      const targetDictName = this.generateDictionaryName(this.targetId);
      let spellCheckInstance = null;
      if (typeof window.WEBSPELLCHECKER !== "undefined" && window.WEBSPELLCHECKER?.init) {
        const spellCheckers = window.WEBSPELLCHECKER.getInstances();
        spellCheckInstance = spellCheckers[0];
      }
      let sourceList;
      if (this.parsedExtractedWords?.length) {
        sourceList = this.parsedExtractedWords.split(" ");
      } else if (!this.sourceId) {
        sourceList = mccalmontWordList;
      } else {
        await new Promise((res, rej) => {
          spellCheckInstance.getUserDictionary(sourceDictName, res, rej);
        }).then(res => {
          sourceList = res?.wordlist;
        });
      }
      this.totalPending = sourceList.length;
      for (const word of sourceList) {
        this.message = `Adding ${word}...`;
        const index = sourceList.indexOf(word);
        this.currentIndex = index + 1;
        const response = await this.addWord(targetDictName, word);
        if (response.status === 200) {
          console.log(`Added ${word} to dictionary.`);
          this.successfulWordCount++;
        } else {
          console.log(`Error adding ${word} to dictionary: ${response.status}`);
          this.failedWordCount++;
          this.failedWords.push(word);
        }
        if (index === sourceList.length - 1) {
          window.alert(
            `Successfully added ${this.successfulWordCount} words to dictionary. ${
              this.failedWordCount ? `${this.failedWordCount} words could not be added.` : ""
            }`
          );
          this.isRunning = false;
        }
      }
    },
    generateDictionaryName(userId) {
      const newDictionaryName = `${userId.replace(/\W/g, "")}Dictionary`;
      return newDictionaryName;
    },
    async addWord(dictionaryName, word) {
      return await fetch("https://svc.webspellchecker.net/spellcheck31/api", {
        method: "POST",
        body: `customerid=${process.env.VUE_APP_DEFAULT_SPELLCHECK_KEY}&format=json&app_type=proofreader_editable_controls&cmd=user_dictionary&action=addword&name=${dictionaryName}&word=${word}`
      });
    },
    resetCount() {
      this.successfulWordCount = 0;
      this.failedWordCount = 0;
      this.failedWords = [];
      this.currentIndex = null;
      this.totalPending = null;
    },
    async extractWordsFromMacros() {
      const words = await MacrosApi.extractMacroWords(this.usersToExtract, this.labToExtract);
      const isPlural = this.usersToExtract.length > 1;
      if (!words.length) {
        window.alert(
          `Selected user${isPlural ? "s" : ""} do${
            isPlural ? "" : "es"
          } not have any words to extract.`
        );
        return;
      }
      this.extractedWords = words.join("\n");
      this.isExtractMacroWordsOpen = false;
    },
    userDisplayName(data) {
      return `${data.lastName}, ${data.firstName}`;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      availableLabs: state => state.availableLabs
    }),
    parsedExtractedWords() {
      if (!this.extractedWords?.length) {
        return "";
      }
      return this.extractedWords.replaceAll(/[^a-z0-9']/gi, " ");
    },
    hasExtractedWords() {
      return this.extractedWords.length > 0;
    },
    userOptions() {
      return new DataSource({
        store: UsersApi.labUsersStore,
        sort: "lastName",
        filter: ["userTypeId", "=", UserTypesEnum.LabUser]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: $white;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}
</style>
